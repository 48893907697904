<template>
  <div class="card shadow-sm">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        กำหนดงวดบัญชี : OP1
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" />
          </span>
          เพิ่มข้อมูล
        </button>
      </div>
    </div>
    <div class="card-body table-responsive">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>งวดที่</th>
              <th>วันที่เริ่มงวด</th>
              <th>วันที่สิ้นงวด</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ item.value1 }}</td>
              <td>{{ item.value2 }}</td>
              <td>{{ item.value3 }}</td>
              <td>{{ item.value4 }}</td>
              <td>
                <div class="row">
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToEdit()"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="goToDelete()"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card-footer">
      <ul class="pagination">
        <li
          :class="
            hoverNumber === 1
              ? 'page-item previous disabled'
              : 'page-item previous'
          "
        >
          <a class="page-link" @click="changePage(hoverNumber - 1)"
            ><i class="previous"></i
          ></a>
        </li>
        <li v-for="n in countPagination" :key="n" class="page-item">
          <a
            class="page-link"
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;'
            "
            >{{ n }}</a
          >
        </li>
        <li
          :class="
            hoverNumber === countPagination
              ? 'items. page-item next disabled'
              : 'items. page-item next'
          "
        >
          <a class="page-link" @click="changePage(hoverNumber + 1)"
            ><i class="next"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        value1: "1",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "2",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "3",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "4",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "5",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "6",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "7",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "8",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "9",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "10",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "11",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "12",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "13",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "14",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "15",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "16",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "17",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "18",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "19",
        value2: "2",
        value3: "3",
        value4: "4",
      },
      {
        value1: "20",
        value2: "2",
        value3: "3",
        value4: "4",
      },
    ],
    countPagination: 0,
    dataItemsShow: [],
    hoverNumber: 1,
  }),
  async mounted() {
    await this.setCountPagination();
    await this.filterItems();
  },
  methods: {
    filterItems() {
      this.dataItemsShow = this.items.slice(0, 10);
    },
    setCountPagination() {
      this.countPagination = this.items.length / 10;
    },
    changePage(n) {
      this.hoverNumber = n;
      if (n === 1) {
        this.dataItemsShow = this.items.slice(0, 10);
      }
      if (n === 2) {
        this.dataItemsShow = this.items.slice(10, 20);
      }
    },
    goToNew() {
      this.$router.push("/manage-system/op1/new");
    },
    goToEdit() {
      this.$router.push({
        name: "OP1-edit",
        query: {
          id: "123",
        },
      });
    },
    goToDelete() {},
  },
};
</script>
